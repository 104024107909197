import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 35;

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tableHeader: {
        "& th": {
          fontWeight: "bold",
        },
      },
      dataTableHeader: {
        fontWeight: "bold",
      },
      acknowledgedAlarmDark: {
        "& td": {
          backgroundColor: "#84ae5c",
          color: theme.palette.common.white,
        },
      },
      acknowledgedAlarmLight: {
        "& td": {
          backgroundColor: "#dc5950",
          color: theme.palette.common.white,
        },
      },
      errorAlarmLight: {
        "& td": {
          backgroundColor: "#f57200",
          color: theme.palette.common.white,
        },
      },
      errorAlarmDark: {
        "& td": {
          backgroundColor: "#0a8dff",
          color: theme.palette.common.white,
        },
      },
      appBar: {
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        color: "#2f2d2d",
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}%)`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: `calc(${drawerWidth}%)`,
      },
      title: {
        flexGrow: 1,
      },
      hide: {
        display: "none",
      },
      drawer: {
        width: `calc(${drawerWidth}%)`,
        flexShrink: 0,
      },
      drawerPaper: {
        width: `calc(${drawerWidth}%)`,
      },
      filterDrawerPaper: {
        width: `calc(${drawerWidth / 2}%)`,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      contentShift: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        width: `calc(100% - ${drawerWidth}%)`,
      },
      small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    }),
  { index: 1 },
);
