import { useEffect, useState, useContext, createContext } from "react";
import * as msal from "msal";
import LoggingIn from "../pages/LoggingIn";
import { AUTHORITY, TENANT_ID, CLIENT_ID, REDIRECT_URI } from "../environment-variables";

interface AuthContextData {
  loggedIn: boolean;
  loading: boolean;
  getAccessToken: () => Promise<string>;
  getLoggedInUsername: () => string | null;
  getLoggedInName: () => string | null;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

let initPromise: Promise<void>;
let msalApplication: msal.UserAgentApplication;
let authRequest = { scopes: [""] };

const coreInit = async () => {
  const msalConfig: msal.Configuration = {
    auth: {
      clientId: CLIENT_ID,
      authority: `${AUTHORITY}${TENANT_ID}`,
      redirectUri: REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };

  authRequest = {
    scopes: [`api://${CLIENT_ID}/MSDashboards.ReadWrite`],
  };

  msalApplication = new msal.UserAgentApplication(msalConfig);
  msalApplication.handleRedirectCallback(() => {});
};

const initApplication = () => {
  if (!initPromise) {
    initPromise = coreInit();
  }
  return initPromise;
};

const getAccessToken = async () => {
  try {
    const accessTokenResponse = await msalApplication.acquireTokenSilent(authRequest);
    return accessTokenResponse.accessToken;
  } catch {
    msalApplication.acquireTokenRedirect(authRequest);
    return "";
  }
};

const getLoggedInUsername = () => {
  const user = msalApplication.getAccount();
  return user ? user.userName : null;
};

const getLoggedInName = () => {
  const user = msalApplication.getAccount();
  return user ? user.name : null;
};

const performLogin = () => msalApplication.loginRedirect(authRequest);

const AuthProvider: React.FC = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLogin = async () => {
      setLoading(true);
      await initApplication();
      const user = msalApplication.getAccount();

      if (user) {
        try {
          const token = await getAccessToken();
          setLoggedIn(!!token);
        } catch {
          setLoggedIn(false);
        }
      } else {
        setLoggedIn(false);
      }
      setLoading(false);
    };

    checkLogin();
  }, []);

  useEffect(() => {
    if (!loading && !loggedIn) {
      performLogin();
    }
  }, [loading, loggedIn]);

  const data: AuthContextData = {
    getAccessToken,
    getLoggedInUsername,
    getLoggedInName,
    loading,
    loggedIn,
  };

  if (!loggedIn || loading) {
    return <LoggingIn />;
  }

  if (window.self === window.top) {
    return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
  } else {
    return <></>;
  }
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, getAccessToken };
