import { FC } from "react";
import { Badge } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

enum ChevronDirection {
  Left = 0,
  Right,
}

interface DrawerChevronProps {
  chevronDirection: ChevronDirection;
  badgeContent?: number | string;
}

const DrawerChevron: FC<DrawerChevronProps> = ({ chevronDirection, badgeContent }) => {
  return (
    <Badge badgeContent={badgeContent} color="secondary" showZero>
      {chevronDirection === ChevronDirection.Left ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </Badge>
  );
};

export { DrawerChevron, ChevronDirection };
