import { API_URI } from "../environment-variables";

// SPF Records
const getAlarms: () => string = () => `${API_URI}/Alarms`;
const getOfflineServers: () => string = () => `${API_URI}/OfflineServers`;
const getTestSubTypes: () => string = () => `${API_URI}/testSubTypes`;
const addTestSubType: () => string = () => `${API_URI}/testSubTypes`;
const deleteTestSubType: () => string = () => `${API_URI}/testSubTypes`;
const updateTestSubType: () => string = () => `${API_URI}/testSubTypes`;

export { getAlarms, getOfflineServers, getTestSubTypes, addTestSubType, deleteTestSubType, updateTestSubType };
