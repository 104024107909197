import { FC } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { deleteTestSubType } from "../api/endpoints";
import { useSnackbar } from "notistack";
import TestSubType from "../models/TestSubType";

interface ConfirmTestSubTypeDeleteProps {
  toDelete: TestSubType | undefined;
  open: boolean;
  handleDialog(): void;
  refreshTable(): void;
}

const ConfirmTestSubTypeDelete: FC<ConfirmTestSubTypeDeleteProps> = ({
  toDelete,
  open,
  handleDialog,
  refreshTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleDialog();
    enqueueSnackbar("Deleting...");
    const response = await fetch(deleteTestSubType(), {
      method: "DELETE",
      mode: "cors",
      body: JSON.stringify(toDelete!),
    });
    if (!(await response.ok)) {
      enqueueSnackbar("Something went wrong...", { variant: "error" });
    }
    enqueueSnackbar("Deleted!", { variant: "success" });

    refreshTable();
  };

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle>Delete Test Sub Type</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this Test Sub Type?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmTestSubTypeDelete;
