import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  CircularProgress,
  Container,
  CssBaseline,
  Switch,
  Toolbar,
  AppBar,
  Typography,
  Fab,
} from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { useStyles } from "../hooks/useStyles";
import { useGetAndUpdate } from "../hooks/useApi";
import { getTestSubTypes, updateTestSubType } from "../api/endpoints";
import { DrawerChevron, ChevronDirection } from "../components/DrawerChevron";
import ConfirmTestSubTypeDelete from "../components/ConfirmTestSubTypeDelete";
import NewTestSubType from "../components/NewTestSubType";
import TestSubType from "../models/TestSubType";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";

const TestSubTypePage: FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [newOpen, setNewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [togglesDisabled, setTogglesDisabled] = useState(false);
  const [testSubTypeToDelete, setTestSubTypeToDelete] = useState<TestSubType | undefined>(undefined);
  const [testSubTypes, setTestSubTypes, , , refreshTable] = useGetAndUpdate<TestSubType[]>(getTestSubTypes());

  const handleDeleteDialog = () => {
    if (deleteOpen) setDeleteOpen(false);
    else setDeleteOpen(true);
  };

  const handleNewDialog = () => {
    if (newOpen) setNewOpen(false);
    else setNewOpen(true);
  };

  const alphabetically = (a: TestSubType, b: TestSubType) => {
    if (a.id === b.id) {
      return 0;
    }
    return a.id < b.id ? -1 : 1;
  };

  const toggleFailSnackbar = () => {
    enqueueSnackbar("Uh oh, something went wrong...", { variant: "error" });
  };

  const handleToggle = async (_: React.ChangeEvent<HTMLInputElement>, toToggle: TestSubType) => {
    // Flip toggle for instant feedback
    let newTestSubTypes = [
      ...testSubTypes!.filter((t) => t.id != toToggle.id),
      { ...toToggle, isEnabled: !toToggle.isEnabled },
    ];
    setTestSubTypes(newTestSubTypes.sort(alphabetically));

    // Disabled toggles to prevent rapid fire
    setTogglesDisabled(true);

    // PUT testSubType with flipped toggle to azure function
    await fetch(updateTestSubType(), {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({ ...toToggle, isEnabled: !toToggle.isEnabled }),
    })
      .then((response) => {
        // If bad response, likely a conflict => refresh data
        if (!response.ok) {
          toggleFailSnackbar();
          refreshTable();
        } else {
          // If success, overwrite the PUTted testSubType to preserve new eTag
          response.json().then((data) => {
            newTestSubTypes = [...testSubTypes!.filter((t) => t.id != toToggle.id), data];
            setTestSubTypes(newTestSubTypes.sort(alphabetically));
          });
        }
      })
      // i.e. if azure function unreachable => revert to original toggle state
      .catch((_) => {
        toggleFailSnackbar();
        newTestSubTypes = [
          ...testSubTypes!.filter((t) => t.id != toToggle.id),
          { ...toToggle, isEnabled: toToggle.isEnabled },
        ];
        setTestSubTypes(newTestSubTypes.sort(alphabetically));
      });

    // Enable toggles again
    setTogglesDisabled(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar>
          <Tooltip title="Back">
            <IconButton color="inherit" component={NavLink} exact to="/">
              <DrawerChevron chevronDirection={ChevronDirection.Left} />
            </IconButton>
          </Tooltip>
          <Typography variant="h4" gutterBottom style={{ flexGrow: 1, textAlign: "center", marginTop: "2vh" }}>
            Test Sub Types
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth="md">
        <Typography style={{ marginTop: "2vh" }}>
          Note: It may take several minutes for any changes made here to reflect on the dashboard.
        </Typography>
        <TableContainer component={Paper} style={{ margin: "2vh 0" }}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>Test Type</TableCell>
                <TableCell>Friendly Name</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {testSubTypes === undefined ? (
              <caption style={{ textAlign: "center" }}>
                <CircularProgress />
              </caption>
            ) : (
              <TableBody>
                {testSubTypes.map((testSubType) => (
                  <TableRow key={testSubType.id}>
                    <TableCell>{testSubType.id}</TableCell>
                    <TableCell>{testSubType.friendlyName}</TableCell>
                    <TableCell>
                      <Switch
                        checked={testSubType.isEnabled}
                        color="primary"
                        disabled={togglesDisabled}
                        onChange={(e) => handleToggle(e, testSubType)}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setTestSubTypeToDelete({ ...testSubType });
                            handleDeleteDialog();
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Tooltip title="Add new Test Sub Type">
          <Fab color="primary" onClick={handleNewDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Container>
      <ConfirmTestSubTypeDelete
        toDelete={testSubTypeToDelete}
        open={deleteOpen}
        handleDialog={handleDeleteDialog}
        refreshTable={refreshTable}
      />
      <NewTestSubType open={newOpen} handleDialog={handleNewDialog} refreshTable={refreshTable} />
    </>
  );
};

export default TestSubTypePage;
