declare global {
  interface Window {
    __msdashboards_env: {
      API_URI: string;
      AUTHORITY: string;
      TENANT_ID: string;
      CLIENT_ID: string;
      REDIRECT_URI: string;
      LOGIC_MONITOR_URI: string;
    };
  }
}

const API_URI = window.__msdashboards_env.API_URI;
const AUTHORITY = window.__msdashboards_env.AUTHORITY;
const TENANT_ID = window.__msdashboards_env.TENANT_ID;
const CLIENT_ID = window.__msdashboards_env.CLIENT_ID;
const REDIRECT_URI = window.__msdashboards_env.REDIRECT_URI;
const LOGIC_MONITOR_URI = window.__msdashboards_env.LOGIC_MONITOR_URI;

export { API_URI, AUTHORITY, TENANT_ID, CLIENT_ID, REDIRECT_URI, LOGIC_MONITOR_URI };
