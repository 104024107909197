import React from "react";
import { getAccessToken } from "../services/authentication";

const constructHeaders = async (otherHeaders?: Headers) => {
  const token: string | undefined = await getAccessToken(); // Call to your favourite authentication framework. Or MSAL.

  const headers = otherHeaders ?? new Headers();

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  return headers;
};

const responseAsTyped = async <T>(response: Response): Promise<T | undefined> => {
  if (response && response.status !== 204) {
    return (await response.json()) as T;
  }

  return undefined;
};

const addEtagHeaderIfKnown = (existingHeaders: Headers, etag: string | null): Headers => {
  if (etag) {
    existingHeaders.append("If-Match", etag);
  }
  return existingHeaders;
};

interface ApiHookOptions {
  /**
   * @remarks
   * Callback to execute if the HTTP status code is an error. Alternative to using the errorHttpStatusCode status.
   */
  onError?: (httpStatusCode: number, resultBody?: string) => void;
  /**
   * @remarks
   * If true (default) gets the initial state from the API as soon as the component loads. Or else call refresh() when ready to get the state.
   */
  shouldGetImmediately?: boolean;
}

interface ApiHookOptionsTyped<TEntity> extends ApiHookOptions {
  /**
   * @remarks
   * The Hook implements ETags by default, which are automatically extracted from the ETag header and then put back into the If-Match header.
   * However, if the ETag is provided on the entity instead of the http headers, then you can supply a function here which is used for getting the ETag from an entity property.
   */
  etagProvider?: (entity: TEntity) => string; // If function is undefined then defaults to using the ETag from headers
}

/**
 * @remarks
 * A Hook for performing Get, Put, and Delete operations to an API endpoint address.
 * Presents methods similar to React.useState for holding state in-memory, and provides a put() method which can be called for sending that state to the API.
 * Also provides a refresh() method for retrieving the state from the API, after which it will be set into the data hook property automatically.
 * If the API uses ETags for entity version control, then the ETag header will automatically be stored upon each Get and injected into the "In-Match" header for any outbound Put.
 * @param uri - API endpoint to use for GET and PUT.
 * @param options - Optional options object.
 * @returns data: in-memory object equivalent to React.useState
 * @returns setData: in-memory object equivalent to React.useState
 * @returns put: a method which can be called to put the current in-memory data back to the API. Alternatively, supply a data structure, and this will be sent immediately (and re-stored in the data hook.) True if success.
 * @returns isLoading: true if any API operation is in progress.
 * @returns refresh: a method which can be called to re-fetch from the API and update the in-memory data property accordingly. True if success.
 * @returns delete: a method which can be called to re-fetch from the API and update the in-memory data property accordingly. True if success.
 * @returns errorHttpStatusCode: if the last API operation resulted in an error then this is the code.
 */
const useGetAndUpdate = <T>(
  uri: string | null,
  options?: ApiHookOptions & ApiHookOptionsTyped<T>,
): [
  T | undefined, // data
  React.Dispatch<React.SetStateAction<T | undefined>>, // setData
  (toPut?: T | undefined) => Promise<boolean>, // put()
  boolean, // isLoading
  () => Promise<boolean>, // refresh()
  () => Promise<boolean>, // delete()
  number | null, // Last http error code (or null if success)
] => {
  const [data, setData] = React.useState<T | undefined>(undefined);
  const [etag, setEtag] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(!!uri && (options?.shouldGetImmediately ?? true));
  const [errorCode, setErrorCode] = React.useState<number | null>(null);

  const lastActiveRequestController = React.useRef<AbortController>();

  const responseAsData = async (response: Response): Promise<T | undefined> => {
    const entity = await responseAsTyped<T | undefined>(response);
    if (options?.etagProvider) {
      setEtag(entity ? options.etagProvider(entity) : null);
    } else {
      setEtag(response.headers.get("ETag"));
    }

    return entity;
  };

  const apiOperationAsync = async (requestInit: RequestInit): Promise<boolean> => {
    if (uri === null) {
      setEtag(null);
      setErrorCode(null);
      setData(undefined);
      return false;
    }

    setIsLoading(true);
    try {
      const response = await fetch(uri, requestInit);
      if (response.ok) {
        setData(await responseAsData(response));
        setErrorCode(null);
        setIsLoading(false);
        return true;
      } else {
        setData(undefined);
        setErrorCode(response.status);
        if (options?.onError) options.onError(response.status, await response.text());
        setIsLoading(false);
      }
    } catch (err: any) {
      if (err.name !== "AbortError") {
        setErrorCode(-1);
        if (options?.onError) options.onError(-1, "Could not fetch data");
        setIsLoading(false);
      }
    }

    return false;
  };

  const apiGetAsync = async (): Promise<boolean> => {
    //Abort the last request - this is to ensure that the hook only has one active get request at any one time
    lastActiveRequestController.current?.abort();

    //Create new abort controller and signal for new request
    const controller = new AbortController();
    lastActiveRequestController.current = controller;

    const headers = await constructHeaders();
    const requestInit: RequestInit = {
      headers,
      method: "GET",
      signal: controller.signal,
    };

    return await apiOperationAsync(requestInit);
  };

  const apiPutAsync = async (toPut?: T | undefined): Promise<boolean> => {
    const headers = addEtagHeaderIfKnown(await constructHeaders(), etag);
    const requestInit: RequestInit = {
      headers,
      method: "PUT",
      body: JSON.stringify(toPut !== undefined ? toPut : data),
    };

    return await apiOperationAsync(requestInit);
  };

  const apiDeleteAsync = async (): Promise<boolean> => {
    const headers = addEtagHeaderIfKnown(await constructHeaders(), etag);
    const requestInit: RequestInit = {
      headers,
      method: "DELETE",
    };

    return await apiOperationAsync(requestInit);
  };

  React.useEffect(() => {
    if (options?.shouldGetImmediately ?? true) {
      const doit = async () => apiGetAsync();
      doit();
    }
  }, [uri, options?.shouldGetImmediately]);

  React.useEffect(() => {
    return () => {
      lastActiveRequestController.current?.abort();
    };
  }, []);

  return [data, setData, apiPutAsync, isLoading, apiGetAsync, apiDeleteAsync, errorCode];
};

/**
 * @remarks
 * A Hook for performing Post operations to an API endpoint address.
 * Presents methods similar to React.useState for holding state in-memory, and provides a post() method which can be called for sending that state to the API.
 * The response from the Post operation then appears in the response variable.
 * @param uri - API endpoint to use for POST.
 * @param initialState - Initial state to use in the data and setData hook.
 * @param options - Optional options object.
 * @returns data: in-memory object equivalent to React.useState
 * @returns setData: in-memory object equivalent to React.useState
 * @returns post: a method which can be called to put the current in-memory data back to the API. Alternatively, supply a data structure, and this will be sent immediately (and re-stored in the data hook.) True if success.
 * @returns response: what is returned from the post method.
 * @returns isloading: true if any API operation is in progress.
 * @returns errorHttpStatusCode: if the last API operation resulted in an error then this is the code.
 */
const usePostWith = <TPost, TResponse>(
  uri: string | null,
  initialState: TPost,
  options?: ApiHookOptions,
): [
  TPost, // data
  React.Dispatch<React.SetStateAction<TPost>>, // setData
  (toPost?: TPost | undefined) => Promise<boolean>, // post()
  TResponse | undefined, // response
  boolean, // isLoading
  number | null, // Last http error code (or null if success)
] => {
  const [response, setResponse] = React.useState<TResponse | undefined>();
  const [data, setData] = React.useState<TPost>(initialState);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<number | null>(null);

  const responseAsData = (response: Response): Promise<TResponse | undefined> => {
    return responseAsTyped(response);
  };

  const apiOperationAsync = async (requestInit: RequestInit): Promise<boolean> => {
    if (uri === null) {
      return false;
    }

    setIsLoading(true);
    try {
      const response = await fetch(uri, requestInit);
      if (response.ok) {
        setResponse(await responseAsData(response));
        setErrorCode(null);
        return true;
      } else {
        setResponse(undefined);
        setErrorCode(response.status);
        if (options?.onError) options.onError(response.status, await response.text());
        return false;
      }
    } catch {
      setResponse(undefined);
      setErrorCode(-1);
      if (options?.onError) options.onError(-1, "Could not fetch data");
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const apiPostAsync = async (toPost?: TPost | undefined): Promise<boolean> => {
    const headers = await constructHeaders();
    const requestInit: RequestInit = {
      headers,
      method: "POST",
      body: JSON.stringify(toPost !== undefined ? toPost : data),
    };
    return await apiOperationAsync(requestInit);
  };

  return [data, setData, apiPostAsync, response, isLoading, errorCode];
};

/**
 * @remarks
 * A Hook for performing Post operations to an API endpoint address where no entity body is required.
 * The response from the Post operation then appears in the response variable.
 * @param uri - API endpoint to use for POST.
 * @param options - Optional options object.
 * @returns post: a method which can be called to send an empty Post to the API. True if success.
 * @returns response: what is returned from the post method.
 * @returns isloading: true if any API operation is in progress.
 * @returns errorHttpStatusCode: if the last API operation resulted in an error then this is the code.
 */
const usePostAt = <TResponse>(
  uri: string | null,
  options?: ApiHookOptions,
): [() => Promise<boolean>, TResponse | undefined, boolean, number | null] => {
  const [, , post, response, isLoading, errorHttpStatusCode] = usePostWith<undefined, TResponse | undefined>(
    uri,
    undefined,
    options,
  );

  return [post, response, isLoading, errorHttpStatusCode];
};

/**
 * @remarks
 * A Hook for performing Get operations to an API endpoint address.
 * @param uri - API endpoint to use for GET.
 * @param options - Optional options object.
 * @returns [data, isLoading, refresh(), errorHttpStatusCode]
 */
const useGet = <T>(
  uri: string | null,
  options?: ApiHookOptions | ApiHookOptionsTyped<T>,
): [T | undefined, boolean, () => Promise<boolean>, number | null] => {
  const [data, , , isLoading, refresh, , errorHttpStatusCode] = useGetAndUpdate<T>(uri, options);

  return [data, isLoading, refresh, errorHttpStatusCode];
};

export { useGetAndUpdate, usePostWith, usePostAt, useGet };
