import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Typography, Toolbar, CssBaseline, Drawer, IconButton, Switch, Avatar } from "@material-ui/core";
import LightModeIcon from "@material-ui/icons/WbIncandescent";
import DarkModeIcon from "@material-ui/icons/WbIncandescentOutlined";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MenuIcon from "@material-ui/icons/Menu";
import { DrawerChevron, ChevronDirection } from "../components/DrawerChevron";
import { useGet } from "../hooks/useApi";
import { getAlarms } from "../api/endpoints";
import Alarm from "../models/Alarm";
import AlarmTable from "../components/tables/AlarmsTable";
import { Settings } from "@material-ui/icons";
import { useStyles } from "../hooks/useStyles";

interface HomePageProps {
  darkMode: boolean;
  toggleTheme(): void;
}

const HomePage: FC<HomePageProps> = ({ darkMode, toggleTheme }) => {
  const classes = useStyles();
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [lockDrawer, setLockDrawer] = useState<boolean>(false);
  const [alarms, alarmsLoading, refreshAlarms] = useGet<Alarm[]>(getAlarms());

  const [time, setTime] = useState(new Date());

  // Timer is set to update state time every 60 seconds, which in turn triggers useEffect for refreshing alerts and offlineServers
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    refreshAlarms();
  }, [time]);

  return (
    <>
      <CssBaseline />
      <Toolbar>
        <IconButton color="inherit" aria-label="openFilters" onClick={() => setFiltersOpen(!filtersOpen)}>
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h4"
          gutterBottom
          className={classes.title}
          style={{ textAlign: "center", marginTop: "2vh" }}
        >
          NOC Alarms
        </Typography>

        <IconButton color="inherit" aria-label="darkMode" onClick={toggleTheme}>
          {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>

        <Switch
          color="secondary"
          checked={lockDrawer}
          icon={
            <Avatar className={classes.small}>
              <LockOpenIcon fontSize="small" />
            </Avatar>
          }
          checkedIcon={
            <Avatar className={classes.small}>
              <LockIcon fontSize="small" />
            </Avatar>
          }
          onChange={() => setLockDrawer(!lockDrawer)}
        />
      </Toolbar>

      <main>
        <AlarmTable alarms={alarms} alarmsLoading={alarmsLoading} />
      </main>

      <Container maxWidth="xl">
        <Drawer
          anchor="left"
          open={filtersOpen}
          onClose={() => setFiltersOpen(!filtersOpen)}
          classes={{
            paper: classes.filterDrawerPaper,
          }}
        >
          <div>
            <IconButton onClick={() => setFiltersOpen(!filtersOpen)} style={{ float: "right", margin: "0.8vh" }}>
              <DrawerChevron chevronDirection={ChevronDirection.Left} />
            </IconButton>
            <IconButton
              exact
              to="testSubTypes"
              component={NavLink}
              style={{ position: "absolute", bottom: "0", margin: "0.8vh" }}
            >
              <Settings />
            </IconButton>
          </div>
        </Drawer>
      </Container>
    </>
  );
};

export default HomePage;
