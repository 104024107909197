import { FC } from "react";
import clsx from "clsx";
import MUIDataTable from "mui-datatables";
import Alarm from "../../models/Alarm";
import { CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { useStyles } from "../../hooks/useStyles";
import { LOGIC_MONITOR_URI } from "../../environment-variables";

interface AlarmsTableProps {
  alarms: Alarm[] | undefined;
  alarmsLoading: boolean;
}

const createDurationString = (fromDate: Date): string => {
  const now = moment.utc(new Date());
  const end = moment.utc(fromDate);
  const duration = moment.duration(now.diff(end));
  let durationString = "";
  duration.years() !== 0
    ? duration.years() === 1
      ? (durationString += duration.years() + " year, ")
      : (durationString += duration.years() + " years, ")
    : (durationString += "");
  duration.months() !== 0
    ? duration.months() === 1
      ? (durationString += duration.months() + " month, ")
      : (durationString += duration.months() + " months, ")
    : (durationString += " ");
  duration.days() !== 0
    ? duration.days() === 1
      ? (durationString += duration.days() + " day, ")
      : (durationString += duration.days() + " days, ")
    : (durationString += "");
  duration.hours() !== 0 ? (durationString += duration.hours() + "h, ") : (durationString += "");
  duration.minutes() !== 0 ? (durationString += duration.minutes() + "m, ") : (durationString += "");
  duration.seconds() !== 0 ? (durationString += duration.seconds() + "s") : (durationString += "");
  return durationString;
};

const AlarmsTable: FC<AlarmsTableProps> = ({ alarms, alarmsLoading }) => {
  const classes = useStyles();
  const theme = useTheme();

  const setCellHeaderProps = () => ({ className: classes.dataTableHeader });

  const columns = [
    {
      name: "client",
      label: "Client",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: setCellHeaderProps,
      },
    },
    {
      name: "instanceId",
      options: {
        display: false,
        setCellHeaderProps: setCellHeaderProps,
      },
    },
    {
      name: "device",
      label: "Device",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: setCellHeaderProps,
        customBodyRender: (value: any, tableMeta: any) => {
          if (tableMeta.rowData[7] === "LogicMonitor") {
            return (
              <p style={{ cursor: "pointer" }} onClick={() => handleDeviceClick(tableMeta.rowData)}>
                <u>{value}</u>
              </p>
            );
          } else {
            return <p>{value}</p>;
          }
        },
      },
    },
    {
      name: "testName",
      label: "Test Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: setCellHeaderProps,
      },
    },
    {
      name: "currentState",
      label: "Current State",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: setCellHeaderProps,
      },
    },
    {
      name: "criticalSince",
      label: "Time in State",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: setCellHeaderProps,
        customBodyRender: (value: Date) => <>{createDurationString(value)}</>,
      },
    },
    {
      name: "acknowledgment",
      label: "Acknowledgment",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: setCellHeaderProps,
      },
    },

    {
      name: "origin",
      label: "Origin",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: setCellHeaderProps,
      },
    },
  ];

  const handleDeviceClick = (rowData: any) => {
    window.open(LOGIC_MONITOR_URI + rowData[1]);
  };

  return (
    <>
      <MUIDataTable
        title={"Alarms"}
        data={alarms!}
        columns={columns}
        options={{
          selectableRows: "none",
          pagination: false,
          download: false,
          print: false,
          filterType: "multiselect",
          textLabels: {
            body: {
              noMatch: alarmsLoading ? <CircularProgress /> : "Sorry, there is no matching data to display",
            },
          },
          setTableProps: () => {
            return {
              size: "small",
            };
          },
          setRowProps: (row: any[]) => {
            return {
              className: clsx(
                row[4] === "Error"
                  ? theme.palette.type === "dark"
                    ? classes.errorAlarmDark
                    : classes.errorAlarmLight
                  : theme.palette.type === "dark"
                  ? row[6] !== null && classes.acknowledgedAlarmDark
                  : row[6] === null && classes.acknowledgedAlarmLight,
              ),
            };
          },
        }}
      />
    </>
  );
};

export default AlarmsTable;
