import { FC } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import HomePage from "./pages/HomePage";
import usePersistedState from "./hooks/usePersistedState";
import { AuthProvider } from "./services/authentication";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TestSubTypePage from "./pages/TestSubTypePage";
import PageNotFound from "./pages/PageNotFound";
import { SnackbarProvider } from "notistack";

const App: FC = () => {
  const [darkMode, setDarkMode] = usePersistedState(false, "dark-mode");
  const palletType = darkMode ? "dark" : "light";

  const appTheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        main: "#4fbddb",
      },
    },
  });

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <AuthProvider>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={appTheme}>
          <Router>
            <Switch>
              <Route exact path="/">
                <HomePage darkMode={darkMode} toggleTheme={toggleTheme} />
              </Route>
              <Route path="/testSubTypes">
                <TestSubTypePage />
              </Route>
              <Route>
                <PageNotFound />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
};

export default App;
