import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Tooltip,
  Switch,
  FormControl,
} from "@material-ui/core";
import { addTestSubType } from "../api/endpoints";
import TestSubType from "../models/TestSubType";
import { usePostWith } from "../hooks/useApi";
import { useSnackbar } from "notistack";

interface NewTestSubTypeProps {
  open: boolean;
  handleDialog(): void;
  refreshTable(): void;
}

const emptyTest: TestSubType = { id: "", isEnabled: true };

const NewTestSubType: FC<NewTestSubTypeProps> = ({ open, handleDialog, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const errorHandler = (httpStatus: number) => {
    switch (httpStatus) {
      case 500:
        enqueueSnackbar("Uh oh, something went wrong...", { variant: "error" });
    }
  };

  const [newTest, setNewTest, postNewTest] = usePostWith<TestSubType, any>(
    addTestSubType(),
    {
      id: "",
      isEnabled: true,
    },
    { onError: errorHandler },
  );

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle>New Test Sub Type</DialogTitle>
      <DialogContent>
        <FormControl>
          <TextField
            variant="outlined"
            label="Test Name"
            value={newTest.id}
            onChange={(e) => {
              setNewTest({ ...newTest, id: e.target.value });
            }}
            required
            style={{ margin: "1vh 0" }}
          />
          <TextField
            variant="outlined"
            label="Friendly Name"
            value={newTest.friendlyName}
            onChange={(e) => {
              setNewTest({ ...newTest, friendlyName: e.target.value });
            }}
            style={{ margin: "1vh 0" }}
          />
          <Tooltip title="Toggle Enabled">
            <Switch
              checked={newTest.isEnabled}
              color="primary"
              onChange={(_) => {
                setNewTest({ ...newTest, isEnabled: !newTest.isEnabled });
              }}
            />
          </Tooltip>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await postNewTest();
            handleDialog();
            await refreshTable();
            setNewTest(emptyTest);
          }}
          color="primary"
          disabled={newTest.id === ""}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewTestSubType;
